import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReactPlayer from 'react-player'

// Render a YouTube video player
function App() {
  return (
    <div className="flex h-screen w-full bg-orange content-center	justify-center items-center	">
      <text>Full tutorial site coming soon...</text>
    </div>
    

  );
}

export default App;
